.landing_logo {
    margin-left: 4.5%;
    padding: 32px 0 0 7px;
}

.landing_logo_img {
    height: auto;
    max-width: 18%;
    width: auto;
}

.flex_center {
    display: flex;
    justify-content: center;
    align-items: center;
    /*flex-direction: column;*/
}

.main_wrapper {
    padding-bottom: 10%;
}

.page_name {
    font-family: "Nexa Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 2.25rem;
    line-height: 3rem;
    color: #383839;
    width: 20.25rem;
}

.last_section {
    padding-top: 5%;
}

.text_padding_rw {
    padding-top: 2%;
}

.text_padding {
    padding-top: 3%;
}

.text_size {
    font-family: 'Nexa Bold';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 72px;
    color: #2C2E37;
}

.txt_size {
    font-family: 'Nexa Bold';
    font-style: normal;
    font-weight: 320;
    font-size: 30px;
    line-height: 65px;
    color: #2C2E37;
}

.btn_register_one {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 48px;
    cursor: pointer;
    background: #FFFFFF;
    padding: 0.5rem 2rem;
    color: #1C5371;
    border: 2px solid #1C5371;
    box-sizing: border-box;
    border-radius: 8px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    font-family: "Nexa Bold";
}

.text_register_form {
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;
    line-height: 2rem;
    color: #383839;
    font-family: "Nexa Bold";
    width: 20.25rem;
    padding-top: 0.5rem;
}

.text_register_welcome {
    font-family: 'Nexa Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;
    line-height: 3.625rem;
    color: #000000;
}

.text_margin {
    margin-top: 1rem;
    margin-bottom: 2.5rem;
    width: 100%;
}

.text_margin_two {
    margin-bottom: 3rem;
    margin-top: 1.5rem;
    width: 100%;
}

.text_margin_three {
    margin-top: 0.25rem;
    width: 94%;
}

.text_register_welcome_para {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    text-align: center;
    font-family: 'Nexa Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 1.3rem;
    line-height: 1.8rem;
    color: rgba(56, 56, 57, 0.75);
}

.questionnare {
    font-family: "Nexa Bold";
    cursor: pointer;
    border: 1.5px solid #1C5371;
    border-radius: 10px;
    color: #1C5371;
    padding: 1.3rem 1.8rem;
    background: transparent;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2.125rem;
    letter-spacing: 0.05em;
}

.right_arrow {
    margin-left: 1.188rem;
}

.txt_maturity {
    font-family: 'Nexa Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    color: #2C2E37;
    text-align: center;
    line-height: 96px;
}

.flex_center_end {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.nxt_btn {
    cursor: pointer;
    font-weight: 700;
    font-size: 1.5rem;
    font-style: normal;
    color: #1C5371;
    font-font: "Nexa Bold";
    line-height: 1.813rem;
    box-sizing: border-box;
    padding: 1rem 2rem;
    gap: 8px;
    width: 8rem;
    height: 3.8rem;
    border: 1px solid #1C5371;
    border-radius: 0.625rem;
    background: transparent;
}

.next_checked {
    border: 2px solid #1C5371;
    border-radius: 10px;
    padding: 0.5rem 1.5rem;
    background-color: #FFFFFF;
    color: #1C5371;
    font-family: 'Nexa Bold';
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.8rem;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.main_logo {
    height: auto;
    max-width: 60%;
    width: auto;
}

.main_logo_wrapper {
    text-align: center;
}

.register_heading_div {
    min-height: 39%;
}


.register_form_div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44vw
}


.register_button_div {
    min-height: 25%;
}

@media screen and (min-height: 600px) {

    .main_logo {
        height: auto;
        max-width: 60%;
        width: auto;
    }

    .main_logo_wrapper {
        text-align: center;
    }

    .register_heading_div {
        min-height: 39%;
    }

    .register_form_div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40vw
    }

}

@media screen and (min-height: 832px) {
    /* CSS */
    .landing_logo {
        padding: 64px 0 0 7px;
    }

    .main_logo {
        height: 100%;
        max-width: 80%;
    }

    .main_logo_wrapper {
        text-align: center;
    }

    .text_register_form {
        font-size: 2.25rem;
        line-height: 3rem;
    }

    .txt_maturity {
        font-size: 52px;
    }

    .btn_register_one {
        font-size: 32px;
        padding: 1.5rem 4rem;
        border: 3px solid #1C5371;
    }

    .next_checked {
        font-size: 1.5rem;
        padding: 1rem 1.5rem;
        width: 130px;
    }

    .register_button_div {
        min-height: 40%;
    }

    /* TODO */
    .text_margin {
        margin-top: 5rem;
        margin-bottom: 6.5rem;
        width: 100%;
    }

    .text_margin_two {
        margin-bottom: 3rem;
        margin-top: 1.5rem;
        width: 100%;
    }

    .text_register_welcome_para {

        font-size: 1.6rem;
    }

    .questionnare {
        font-size: 1.75rem;
    }

    .text_padding_rw {
        padding-top: 4%;
    }
}


@media screen and (min-width: 1281px) {
    /* CSS */
    .main_logo {
        height: 100%;
        max-width: 100%;
    }

    .txt_maturity {
        font-size: 64px;
    }

    .register_heading_div {
        min-height: 32%;
    }

    .register_button_div {
        /*min-height: 40%;*/
    }

    .register_form_div {
        width: 40vw
    }

    .register_main_form_div {
        padding-top: 56px;
    }

    .text_padding_rw {
        padding-top: 10%;
    }

    .second_div {
        padding-top: 5%;
    }
}