/*this is the TextArea styling*/
.text_area_style {
        box-sizing: border-box;
        padding: 0.5rem;
        height: 8.625rem;
        font-family: 'Nexa Book';
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem;
        font-size: 12px;
        border: 1px solid #2C2E37;
        border-radius: 12px;
}

.width-wrapper{
        width: 19rem;
}
.text_box_width_response{
        margin: 0 8vw;
}
.text_box_width_review{
        margin: 0;
}

.text_area_style::placeholder {
        font-family: 'Nexa Book';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 1.25rem;
        color: rgba(44, 46, 55, 0.75);
}

.text_area_style:focus {
        outline: none;
}

.open-text-warning{
        font-family: "Verdana";
        color: #2C2E37;
        font-size: 8px;
        text-align: right;
        margin-top: -3px;
}

/*end text area*/

/*checkbox start*/

.checkBox_flex{
        display: flex;
        align-items: center;
        width: 20rem;
        /*background-color: #fbec95;*/
}

.checkBox_flex input[type="checkbox"]:checked {
        border: 0.31rem solid #1C5371;
}

.mc-sty{
        box-sizing: border-box;
        width: 14px;
        height: 14px;
        border: 1px solid #2C2E37;
        border-radius: 3px;
        appearance: none;
}

.checkbox_label_checked{
        font-family: 'Nexa Bold';
        font-style: normal;
        font-weight: 400;
        padding-left: 1.5rem;
        font-size: 12px;
        line-height: 26px;
        color: #2C2E37;
}


.checkbox_label {
        font-family: 'Nexa Book';
        font-style: normal;
        font-weight: 400;
        padding-left: 1.5rem;
        font-size: 12px;
        line-height: 26px;
        color: #2C2E37;
}



.back_btn {
        font-family: 'Nexa Light';
        color: #1C5371;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 1.8rem;
        padding: 0.5rem 1.5rem;
        gap: 8px;
        background-color: white;
        border: none;
}

.next_btn {
        border: 1px solid #1C5371;
        border-radius: 5px;
        padding: 0.5rem 1rem;
        background-color: #1C5371;
        color: #FFFFFF;
        font-family: 'Nexa Light';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 1rem;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}



@media screen and (min-width: 768px) {
        .text_area_style {
                height: 9.625rem;
                line-height: 1.5rem;
                font-size: 14px;
                border-radius: 14px;
        }

        .width-wrapper{
                width: 24rem;
        }

        .text_area_style::placeholder {
                font-size: 14px;
                line-height: 1.5rem;
        }

        .open-text-warning{
                font-size: 9px;
        }

        .back_btn {
                font-size: 14px;
                line-height: 2rem;
        }

        .next_btn {
                border: 2px solid #1C5371;
                border-radius: 7px;
                font-size: 14px;
                line-height: 1.2rem;
        }

        .checkBox_flex{
                width: 24rem;
                /*background-color: #ea1e98;*/
        }

        .checkBox_flex input[type="checkbox"]:checked {
                border: 0.33rem solid #1C5371;
        }

        .mc-sty{
                width: 15px;
                height: 15px;
                border-radius: 4px;
        }

        .checkbox_label_checked{
                padding-left: 1.65rem;
                font-size: 14px;
                line-height: 28px;
        }

        .checkbox_label {
                padding-left: 1.65rem;
                font-size: 14px;
                line-height: 28px;
        }
}


@media screen and (min-width: 1024px) {
        .text_area_style {
                height: 11.625rem;
                line-height: 1.8rem;
                font-size: 16px;
                border-radius: 14px;
        }

        .width-wrapper{
                width: 32.56rem;
        }

        .text_area_style::placeholder {
                font-size: 16px;
                line-height: 1.8rem;
        }

        .open-text-warning{
                font-size: 10px;
        }

        .back_btn {
                font-size: 16px;
                line-height: 2.3rem;
        }

        .next_btn {
                border-radius: 9px;
                font-size: 16px;
                line-height: 1.5rem;
        }

        .checkBox_flex{
                width: 35rem;
        }

        .checkBox_flex input[type="checkbox"]:checked {
                border: 0.36rem solid #1C5371;
        }

        .mc-sty{
                width: 18px;
                height: 18px;
                border-radius: 5px;
                border: 2px solid #2C2E37;
        }

        .checkbox_label_checked{
                padding-left: 2rem;
                font-size: 16px;
                line-height: 36px;
        }

        .checkbox_label {
                padding-left: 2rem;
                font-size: 16px;
                line-height: 36px;
        }

}
@media screen and (min-width: 1280px) {
        .width-wrapper{
                width: 32.56rem;
        }
        .text_box_width_response{
                margin: 0 6vw;
        }
}
@media screen and (min-width: 1440px) {
        .text_area_style {
                height: 13.625rem;
                line-height: 2rem;
                font-size: 18px;
                border-radius: 18px;
        }

        .text_box_width_response{
                margin: 0 7vw;
        }
        .width-wrapper{
                width: 42rem;
        }

        .text_area_style::placeholder {
                font-size: 18px;
                line-height: 2rem;
        }

        .open-text-warning{
                font-size: 12px;
        }

        .back_btn {
                font-size: 24px;
                line-height: 3rem;
        }

        .next_btn {
                border-radius: 10px;
                font-size: 24px;
                line-height: 2.2rem;
        }

        .checkBox_flex{
                width: 45rem;
        }

        .checkBox_flex input[type="checkbox"]:checked {
                border: 0.386rem solid #1C5371;
        }

        .mc-sty{
                width: 1.3rem;
                height: 1.3rem;
                border-radius: 5px;
        }

        .checkbox_label_checked{
                padding-left: 2.2rem;
                font-size: 20px;
                line-height: 40px
        }

        .checkbox_label {
                padding-left: 2.2rem;
                font-size: 20px;
                line-height: 40px;
        }
}
