.main_container {
    /*background-color: #fae7ff;*/
    max-width: 450px;
    margin: 0 auto;
}

.progress_container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0.3%;
    flex-direction: column;
    margin-bottom: 7.5vh;
}

.main_wrapper {
    width: 90%;
}

.main_response_section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.section_container {
    /*background-color: #f8e791;*/
}


.next_checked {
    border: 1px solid #1C5371;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    background-color: #FFFFFF;
    color: #1C5371;
    font-family: 'Nexa Bold';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 1rem;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.review_btn {
    border: 1px solid #1C5371;
    color: #1C5371;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    background-color: #FFFFFF;
    font-family: 'Nexa Light';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    line-height: 1rem;
}

.scrollable_div {
    height: 35vh;
    overflow: scroll;
    overflow-x: hidden;
}

.last_section {
    display: flex;
    justify-content: center;
    align-items: center;
    /*padding-top: 2rem;*/
    padding-right: 4.5rem;
    gap: 2rem;
    position: absolute;
    bottom: 4rem;
}

.question_text {
    font-family: 'Nexa Bold';
    font-size: 12px;
    margin-bottom: 0;
}

.question_description {
    font-family: 'Nexa Light';
    font-size: 8px;
}

.question_text_para {
    padding-left: 3.95rem;
}

.number_text {
    min-width: 3.75rem;
    align-self: start;
    font-family: "Nexa Bold";
    font-size: 12px;
}

.multiple_choice_container {
    width: 91%;
    margin-left: auto;
}

.ot_grid {
    /*padding-top: 2.25rem;*/
    /*margin-left: auto !important;*/
}

.mc_parent {
    /*padding-top: 2.25rem !important;*/
}

.horizontal_padding {
    padding-top: 2.25rem;
}

@media screen and (min-width: 768px) {
    .main_container {
        max-width: 550px;
    }

    .next_checked {
        border: 2px solid #1C5371;
        color: #1C5371;
        border-radius: 7px;
        font-size: 14px;
        line-height: 1.2rem;
    }

    .review_btn {
        border: 2px solid #1C5371;
        border-radius: 7px;
        padding: 0.5rem 1rem;
        font-size: 14px;
        line-height: 1.2rem;
    }

    .question_text {
        font-size: 14px;
    }

    .question_description {
        font-size: 10px;
    }

    .number_text {
        min-width: 3.75rem;
        font-size: 14px;
    }

}

@media screen and (min-width: 1024px) {
    .main_container {
        max-width: 750px;
    }

    .test {
        height: 22px;
        width: 750px;
    }

    .next_checked {
        border: 2px solid #1C5371;
        border-radius: 10px;
        font-size: 16px;
        line-height: 1.5rem;
    }

    .review_btn {
        border: 2px solid #1C5371;
        border-radius: 10px;
        padding: 0.5rem 1rem;
        font-size: 16px;
        line-height: 1.5rem;
    }

    .question_text {
        font-size: 18px;
    }

    .question_description {
        font-size: 14px;
    }

    .number_text {
        min-width: 5rem;
        font-size: 18px;
    }


}



@media screen and (min-width: 1440px) {
    .main_container {
        max-width: 950px;
    }

    .next_checked {
        font-size: 24px;
        line-height: 2.2rem;
    }

    .review_btn {
        font-size: 24px;
        line-height: 2.2rem;
    }

    .question_text {
        font-size: 22px;
    }

    .question_description {
        font-size: 18px;
    }

    .number_text {
        min-width: 6.5rem;
        font-size: 22px;
    }
    .last_section{
        bottom: 3rem;
    }

}