.flex_center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /*background-color: #a59b9b;*/
}

.text_padding {
    padding-top: 3vh;
    padding-bottom: 6vh;
}

.font_style {
    font-family: 'Verdana';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
}

.side_heading {
    font-family: 'Nexa Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1rem;
    color: #0C1F21;
    margin-left: 1rem;
    /*border: 1px solid #000000;*/
}
.whole_container{
    /*border: 1px solid #000000;*/
}
.side_bar {
    width: 1.25rem;
}


.first_section {
    display: flex;
    padding-bottom: 2vh;
}

.submit_btn {
    font-family: "Nexa Bold";
    color: #FFFFFF;
    line-height: 1.813rem;
    font-size: 1rem;
    padding: 16px 30px;
    background: linear-gradient(126.72deg, #00ADD0 -15.85%, #1C5371 73.07%);
    border: 1px solid #1C5371;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.base {
    padding: 2.6rem 4.5rem 11.25rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.error_red {
    color: #ff0303;
}

.imp {
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 5px !important;
}

.number_text {
    font-family: "Nexa Bold";
    font-size: 14px;
}

.review_question_text {
    font-family: "Nexa Bold";
    font-size: 14px;
}

.cb_box {
    padding-bottom: 5vh;
}

.radio_box {
    padding-bottom: 5vh;
}

.ot_box {
    padding-bottom: 5vh;
}
.progress_kind{
    margin: 0;
    font-size: 8px;
}

@media screen and (min-width: 768px) {
    .flex_center{
        /*background-color: #007bff;*/
    }
    .side_heading {
        font-size: 1.2rem;
        line-height: 1.2rem;
        margin-left: 1.2rem;
    }
    .progress_kind{
        font-size: 10px;
    }
    .number_text {
        font-size: 16px;
    }

    .review_question_text {
        font-size: 16px;
    }
}


@media screen and (min-width: 1024px) {
    .flex_center{
        /*background-color: #b700ff;*/
    }
    .side_heading {
        font-size: 1.4rem;
        line-height: 1.4rem;
        margin-left: 1.6rem;
    }
    .progress_kind{
        font-size: 12px;
    }
    .number_text {
        font-size: 18px;
    }

    .review_question_text {
        font-size: 18px;
    }
}


@media screen and (min-width: 1440px) {
    .flex_center{
        /*background-color: #74661f;*/
    }
    .side_heading {
        font-size: 1.6rem;
        line-height: 1.6rem;
        margin-left: 2rem;
    }
    .progress_kind{
        font-size: 14px;
    }
    .number_text {
        font-size: 22px;
    }

    .review_question_text {
        font-size: 22px;
    }
}







/*@media screen and (min-height: 799px) {*/
/*    .side_heading {*/
/*        font-size: 2.25rem;*/
/*        line-height: 2.25rem;*/
/*    }*/
/*    .progress_kind{*/
/*        font-size: 16px;*/
/*    }*/
/*    .number_text{*/
/*        font-size: 1.5rem;*/
/*    }*/
/*    .review_question_text {*/
/*        font-size: 1.5rem;*/
/*    }*/
/*    .submit_btn{*/
/*        font-size: 1.3rem;*/
/*        padding: 28px 35px;*/
/*    }*/
/*}*/
/*@media screen and (min-height: 1000px) {*/
/*    .number_text{*/
/*        font-size: 1.7rem;*/
/*    }*/
/*    .review_question_text {*/
/*        font-size: 1.7rem;*/
/*    }*/
/*    .submit_btn{*/
/*        font-size: 1.5rem;*/
/*        padding: 32px 40px;*/
/*    }*/
/*}*/
