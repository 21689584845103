* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  height: 100%;
  background-color: #f9fbfc;
}

.card-text {
  color: #0f1516;

  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.head_1 {
  color: #0f1516;
  font-family: "Nexa Bold" !important;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

label {
  color: #0f1516;
  font-family: "Nexa Book";
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  padding-top: 6px;
}

.card-title {
  font-size: 16px;
}

.para_1 {
  width: 100% !important;

  color: #777f80 !important;
  font-family: "Nexa Book" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.li_css {
  border: none !important;
  text-align: left;
}

.px_1 {
  border: none;
}

.my_label {
  overflow: hidden;
  color: #0f1516;
  text-overflow: ellipsis;
  font-family: "Nexa Bold" !important;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-left: 15px;
}

.round {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1fb4d2;
  font-family: "Nexa Book" !important;
  color: white;
  font-size: 20px;
  position: relative;
  bottom: 10px;
}

.flex {
  display: flex;
}

.head {
  overflow: hidden;
  color: #0f1516;
  text-overflow: ellipsis;
  font-family: "Nexa Bold";
  font-size: 18px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  margin-top: 7px;
  margin-left: 10px;
}

input[type="checkbox"].custom_checkbox {
  appearance: none !important;

  border: 2px solid #d1eef4 !important;
  outline: none !important;
  cursor: pointer !important;
}

.ln_25 {
  border-radius: 16px;
  background: #fff;
  margin-top: 20px;

  width: 100%;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 12px 32px;
  box-shadow: 0px 2.76726px 2.21381px 0px rgba(7, 99, 223, 0.02),
    0px 6.6501px 5.32008px 0px rgba(7, 99, 223, 0.03),
    0px 12.52155px 10.01724px 0px rgba(7, 99, 223, 0.04),
    0px 22.33631px 17.86905px 0px rgba(7, 99, 223, 0.04);
}

i {
  color: #929999;
}

.box {
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 2.76726px 2.21381px 0px rgba(7, 99, 223, 0.02),
    0px 6.6501px 5.32008px 0px rgba(7, 99, 223, 0.03),
    0px 12.52155px 10.01724px 0px rgba(7, 99, 223, 0.04),
    0px 22.33631px 17.86905px 0px rgba(7, 99, 223, 0.04),
    0px 41.77761px 33.42209px 0px rgba(7, 99, 223, 0.05),
    0px 100px 80px 0px rgba(7, 99, 223, 0.07);
}

hr {
  box-shadow: 0px 2.76726px 2.21381px 0px rgba(7, 99, 223, 0.02),
    0px 6.6501px 5.32008px 0px rgba(7, 99, 223, 0.03),
    0px 12.52155px 10.01724px 0px rgba(7, 99, 223, 0.04),
    0px 22.33631px 17.86905px 0px rgba(7, 99, 223, 0.04),
    0px 41.77761px 33.42209px 0px rgba(7, 99, 223, 0.05),
    0px 100px 80px 0px rgba(7, 99, 223, 0.07);
  color: silver;
}

#port {
  padding: 14px;
  border-radius: 10px;
  box-shadow: 0px 2.76726px 2.21381px 0px rgba(7, 99, 223, 0.02),
    0px 6.6501px 5.32008px 0px rgba(7, 99, 223, 0.03),
    0px 12.52155px 10.01724px 0px rgba(7, 99, 223, 0.04),
    0px 22.33631px 17.86905px 0px rgba(7, 99, 223, 0.04),
    0px 41.77761px 33.42209px 0px rgba(7, 99, 223, 0.05),
    0px 100px 80px 0px rgba(7, 99, 223, 0.07);
}

.head_2 {
  color: #0f1516;
  font-family: Nexa Bold !important;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.para_2 {
  color: #0f1516;
  font-family: Nexa Book;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.arrow {
  text-align: left;
  width: 50%;
  position: relative;
  right: 180px !important;
  bottom: 18px;
}

.card-title {
  color: #0f1516;

  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.flex_3 {
  padding: 0px;
  height: 80px;
  display: flex;
}

#xml {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 16px;
  margin-top: 50px;
}

.carret_up,
.carret_down {
  width: 50px;

  display: flex;
  justify-content: center;
}

.carret_down {
  position: relative;
  bottom: 7px !important;
}

.carret_up {
  position: relative;
  bottom: 13px !important;
}

.seperate {
  display: flex;
  height: 100%;
  align-items: center;
}

.para_4 {
  color: #0f1516;
  font-family: "Nexa Book" !important;

  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 155.556% */
}

.second_head {
  color: #0f1516;
  font-family: "Nexa Bold" !important;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

h3 {
  color: #00add0 !important;
  text-align: left;
  font-family: "Nexa Bold" !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
}

#part_2 {
  padding: 40px;
  background-color: #f1f8fa;
  border-radius: 25px;
}

#part_1 {
  box-shadow: 5px 12px 6px #d4e6f6;
}

#my_round {
  border-radius: 20px;
}

#main {
  background-color: #f1f8fa;
  padding-left: 15px;
  border-radius: 20px;
}

.list-group {
  margin-top: 30px;
}

.extra {
  width: 100% !important;
  padding: 30px !important;
  padding-bottom: 30px !important;
  border-radius: 16px !important;
  border: 1px solid #e5e7eb;
  background: rgba(0, 173, 208, 0.03);
  min-height: 743px;
}

.navbar {
  height: 64px;
  background-color: #2c2e37 !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25) !important;
}

.header_section {
  background: #2c2e37;
}

.cstm_logo {
  height: 49px;
  width: 88px;
}

.custom_notification:hover {
  cursor: pointer;
  background: #000;
}

.cstm_badge {
  display: flex !important;
  width: 16px;
  height: 16px;
  padding: 4px 6px 2px 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 10px !important;
  border-radius: 4px !important;
  background: #db1f5a;
  position: absolute;
  right: 0px;
  top: -2px;
}

.cstm_profile {
  padding-left: 15px;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  margin-left: 15px;
}

.user_name {
  color: #fff;
  font-family: Nexa Bold;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.07px;
}

.user_des {
  color: rgba(255, 255, 255, 0.66);
  font-family: Nexa Bold;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  /* 150% */
  letter-spacing: 0.06px;
  position: relative;
  top: 15px;
  right: 55%;
}

.navbar .navbar_nav .nav_link {
  color: #d1d5db !important;
  font-family: Nexa Bold;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 20px !important;
}

.navbar .navbar_nav .nav_link:hover {
  color: #fff !important;
  font-family: Nexa Bold;
  border-radius: 6px;
  background: #1a1b20;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 20px !important;
}

.profile_dropdown::after {
  content: url("../../assets/caret-down.svg") !important;
  border: 0px !important;
  vertical-align: 0px !important;
}

.profile_dropdown {
  border: 0px !important;
}

.profile_dropdown_menu {
  left: -121px !important;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.profile_dropdown_menu li a {
  color: #6b6b6b;
  font-size: 14px;
  border-bottom: 1px solid rgba(217, 217, 217, 0.2);
  line-height: 20px;
}

.goal_step_section {
  display: flex;
  padding: 24px 40px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: #fff;
  box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.1),
    0px 1px 3px 0px rgba(0, 0, 0, 0.1);
}

.goal_steps ul {
  display: flex;
}

.goal_steps ul li {
  display: inline-flex;
  list-style: none;
  width: 33%;
  color: #777f80;
  text-align: center;
  font-family: Nexa Bold;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.goal_steps ul li:first-child,
.goal_steps ul li:nth-child(2) {
  color: #00add0;
  text-align: center;
  font-family: Nexa Bold;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.goal_steps ul li:first-child .list_divider,
.goal_steps ul li:nth-child(2) .list_divider {
  content: "";
  display: flex;
  padding: 7px 0px;
  align-items: center;
  gap: 10px;
  width: 120px;
  height: 1px;
  border-bottom: 2px solid #00add0;
  margin-left: 10px;
}

.list_divider {
  content: "";
  display: flex;
  padding: 7px 0px;
  align-items: center;
  gap: 10px;
  width: 120px;
  height: 1px;
  border-bottom: 2px solid #e5e7eb;
  margin-left: 10px;
}

.set_your_goal_btn,
.set_your_goal_btn:hover {
  display: flex !important;
  height: 48px;
  padding: 24px 32px !important;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 8px !important;
  border: 1px solid rgba(0, 0, 0, 0.14) !important;
  background: linear-gradient(180deg,
      rgba(52, 161, 183, 0.15) 0%,
      rgba(46, 144, 164, 0) 100%),
    linear-gradient(180deg, #66cee3 0%, #0096b4 100%) !important;
  color: #fff !important;
  font-family: Nexa Bold !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 20px !important;
  letter-spacing: 0.07px;
  float: right;
}

.result_section {
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid #d1eef4;
  background: #f3fafc;
  margin-top: 24px;
}

.result_section h1 {
  color: #0f1516;
  font-family: Nexa Bold;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.result_section p {
  color: #777f80;
  font-family: Nexa Book;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.category_card {
  padding: 20px 32px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 16px;
  background: linear-gradient(0deg, #fff 0%, #fff 100%),
    linear-gradient(43deg, #1586ff 0%, #36baf4 100%);
  box-shadow: 0px 2.767256498336792px 2.2138051986694336px 0px rgba(7, 99, 223, 0.02),
    0px 6.650102138519287px 5.32008171081543px 0px rgba(7, 99, 223, 0.03),
    0px 12.521552085876465px 10.017241477966309px 0px rgba(7, 99, 223, 0.04),
    0px 22.3363094329834px 17.869047164916992px 0px rgba(7, 99, 223, 0.04),
    0px 41.777610778808594px 33.422088623046875px 0px rgba(7, 99, 223, 0.05),
    0px 100px 80px 0px rgba(7, 99, 223, 0.07);
}

.category_list_item {
  padding: 18px 0px;
  align-items: flex-start;
  align-self: stretch;
  border-bottom: 1px solid #d1eef4;
  background: #fff;
}

.category_list_item:last-child {
  padding: 18px 0px;
  align-items: flex-start;
  align-self: stretch;
  background: #fff;
}

.category_list_desc h2 {
  overflow: hidden;
  color: #1d1f2c;
  text-overflow: ellipsis;
  font-family: Nexa Bold;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.category_list_desc ul {
  padding-top: 8px;
  padding-left: 0;
  list-style-type: none;
}

.category_list_desc ul li {
  color: #667085;
  font-family: Nexa Book;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0.07px;
  display: inline-flex;
}

.category_list_desc ul li:before {
  content: "•";
  border-radius: 100px;
  color: rgba(102, 112, 133, 0.2);
  font-size: 27px;
  margin-right: 8px;
  vertical-align: bottom;
}

.card_title {
  font-family: Nexa Book;
  font-size: 28px;
  font-weight: 700;
}

.card_text {
  font-size: 18px;
  font-family: 400;
  font-family: Nexa Book;
  line-height: 28px;
}

.card {
  border-radius: 16px !important;
  padding: 32px !important;
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85%;
}

.arrow_img {
  position: absolute;
  bottom: -109px;
  left: -70px;
}