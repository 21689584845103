.font-face-nb {
  font-family: "Nexa Bold";
}

.flex_space_between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-button:focus {
  box-shadow: none !important;
  outline: none !important;
}

/*.accordion-collapse:not(.show) {*/
/*  display: none !important; !* or any other desired display value *!*/
/*}*/
/*.accordion-item .collapse:not(.show) {*/
/*   display: none !important;*/
/*}*/
.accordion-item{border:none !important;}
@media (min-width: 1920px){
  #chart{
    width:1000px;
    margin:0 auto;
  }

}