.logo{
    max-height: 40%;
    width: 70%;
    max-width: 100%;
}

.response_logo{
    width: 10%;
    max-width: 15%;
    height: auto;
}
.container_margins{
    margin: 4vh 0;
}
.review_text{
    font-family: "Nexa Bold";
}