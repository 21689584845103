.landing_logo{
    margin-left: 4.5%;
}

.flex_center {
    display: flex;
    justify-content: center;
    align-items: center;
    /*flex-direction: column;*/
}
.page_name{
    font-family: "Nexa Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 2.25rem;
    line-height: 3rem;
    color: #383839;
    width: 20.25rem;
}
.text_padding {
    padding-top: 5%;
}
.nxt_btn {
    cursor: pointer;
    font-weight: 700;
    font-size: 1.5rem;
    font-style: normal;
    color: #1C5371;
    font-family: "Nexa Bold";
    line-height: 1.813rem;
    box-sizing: border-box;
    padding: 1rem 2rem;
    gap: 8px;
    width: 8rem;
    height: 3.8rem;
    border: 1px solid #1C5371;
    border-radius: 0.625rem;
    background: transparent;
}