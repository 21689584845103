.headind__card {
    border-top: 10px solid rgba(103, 58, 183);
}

.headind__card .form__heading input {
    font-size: 32px !important;
    color: #202124;
}

.headind__card .form__cat input {
    font-size: 24px;
    color: #202124;
}

.headind__card .form__cat_desc input {
    font-size: 15px;
    color: #202124;
}

.headind__card .form__desc input {
    font-size: 14px;
    color: #202124;
}

.question__field input {
    height: 44px;
}