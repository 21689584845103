:root {
    --form-control-color: #1C5371;
}

.scrollable_div{
    height: 35vh;
    overflow: scroll;
    overflow-x: hidden;
}

.radio_label{
    font-size: 12px;
    margin-left: 1rem;
    font-family: "Nexa Book";
    color: #2C2E37;
}

.radio_label_checked{
    font-size: 12px;
    margin-left: 1rem;
    font-family: "Nexa Bold";
    color: #2C2E37;
}

.radio_container{
    display: flex;
    align-items: center;
    padding-bottom: 0.5rem;
}

input[type="radio"] {
    appearance: none;
    box-sizing: border-box;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 16px;
    height: 16px;
    border: 2px solid var(--form-control-color);
    border-radius: 15px;
    flex: none;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
}
input[type="radio"]::before {
    content: "";
    width: 14px;
    height: 14px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);

}

input[type="radio"]:checked::before {
    transform: scale(0.7);
}

input[type="radio"]:focus {
     outline: max(0, 0.15em) solid var(--form-control-color);
     outline-offset: max(2px, 0.15em);
 }

.radio_container input[type="radio"]:checked + lable {
    font-weight: bold;
    background-color: black;
}

.multiple_choice_container{
    /* change it to percentage*/
    width: 88%;
    margin-left: auto;
}

.multiple_choice_container_review{
    /* change it to percentage*/
    width: 85%;
}

@media screen and (min-width: 768px) {
    .radio_label{
        font-size: 14px;
        line-height: 1.2rem;
    }
    .radio_label_checked{
        font-size: 14px;
        line-height: 1.2rem;
    }
    input[type="radio"] {
        width: 18px;
        height: 18px;
    }
    input[type="radio"]::before {
        width: 16px;
        height: 16px;
    }

}
@media screen and (min-width: 1024px) {
    .radio_label{
        font-size: 16px;
        line-height: 1.6rem;
    }
    .radio_label_checked{
        font-size: 16px;
        line-height: 1.6rem;
    }
    input[type="radio"] {
        width: 22px;
        height: 22px;
    }
    input[type="radio"]::before {
        width: 18px;
        height: 18px;
    }
}

@media screen and (min-width: 1024px) {
    .radio_label{
        font-size: 16px;
        line-height: 2rem;
    }
    .radio_label_checked{
        font-size: 16px;
        line-height: 2rem;
    }
    input[type="radio"] {
        width: 22px;
        height: 22px;
    }
    input[type="radio"]::before {
        width: 18px;
        height: 18px;
    }
}
@media screen and (min-width: 1440px) {

    .radio_label{
        font-size: 20px;
        line-height: 2.6rem;
    }
    .radio_label_checked{
        font-size: 20px;
        line-height: 2.6rem;
    }
    input[type="radio"] {
        width: 26px;
        height:26px;
    }
    input[type="radio"]::before {
        width: 21px;
        height: 21px;
    }

}
