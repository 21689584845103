
.radio-container {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}

.radio-group {
    display: inline-block;
    vertical-align: middle;
}


.divider {
    display: inline-block;
    width: 30%;
    height: 2px;
    margin: 10px auto;
    background-color: #a59b9b;
}

.divider2 {
    display: inline-block;
    width: 30%;
    height: 2px;
    margin: 10px auto;
    background-color: #a59b9b;
}

.radio_divider {
    display: flex;
    padding-bottom: 10px;
}

.label_text_number {
    width: 100%;
    text-align: center;
    /*padding-left: 5px;*/
}

.group_wrapper {
    height: 300px;
}

.blocked_labels {
    width: 13.75rem;
}

.text-class {
    line-height: 2.25rem;
    font-family: "Nexa Book";
    font-size: 1.5rem;
}

.radio_label {
    font-size: 12px;
    margin-left: 1rem;
    font-family: "Nexa Book";
    color: #2C2E37;
    line-height: 1rem;
}

.radio_label_checked {
    font-size: 12px;
    margin-left: 1rem;
    line-height: 1rem;
    font-family: "Nexa Bold";
    color: #2C2E37;
}

.label_content {
    margin-top: 200px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    background-color: #555555;
}

.input-wrapper {
    width: 200px;
}

.horizontal_input[type="radio"] {
    appearance: none;
    box-sizing: border-box;
    background-color: #fff;
    margin:0;
    font: inherit;
    color: currentColor;
    width: 16px;
    height: 16px;
    border: 2px solid var(--form-control-color);
    border-radius: 15px;
    flex: none;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
}
.horizontal_input[type="radio"]::before {
    content: "";
    width: 14px;
    height: 14px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);

}

.horizontal_input[type="radio"]:checked::before {
    transform: scale(0.7);
}

.horizontal_input[type="radio"]:focus {
    outline: max(0, 0.15em) solid var(--form-control-color);
    outline-offset: max(2px, 0.15em);
}

.radio_container .horizontal_input[type="radio"]:checked + lable {
    font-weight: bold;
    background-color: black;
}
.webkit_center{
    text-align: -webkit-center;
    font-size: 14px;
}
.text-container{
    display: flex;
    padding-bottom: 15px;
}
.input-div{
    width: 40%;
    text-align: -webkit-center;
}
.scrollable-div{
    height: 13.5rem;
    overflow: scroll;
    padding: 3px;
    overflow-x: hidden;
}
@media screen and (min-width: 768px) {
    .radio_label{
        font-size: 14px;
        line-height: 1.2rem;
    }
    .radio_label_checked{
        font-size: 14px;
        line-height: 1.2rem;
    }
    input[type="radio"] {
        width: 18px;
        height: 18px;
    }
    input[type="radio"]::before {
        width: 16px;
        height: 16px;
    }

    .webkit_center{
        font-size: 14px;
        padding-bottom: 0;
    }
}
@media screen and (min-width: 1024px) {
    .radio_label{
        font-size: 16px;
        line-height: 1.6rem;
    }
    .radio_label_checked{
        font-size: 16px;
        line-height: 1.6rem;
    }
    input[type="radio"] {
        width: 22px;
        height: 22px;
    }
    input[type="radio"]::before {
        width: 18px;
        height: 18px;
    }

    .webkit_center {
        font-size: 16px;
    }

    .scrollable-div{
        height: 14.5rem;
    }
}
@media screen and (min-width: 1440px) {
    .radio_label{
        font-size: 20px;
        line-height: 2.2rem;
    }
    .radio_label_checked{
        font-size: 20px;
        line-height: 2.2rem;
    }
    input[type="radio"] {
        width: 26px;
        height:26px;
    }
    input[type="radio"]::before {
        width: 21px;
        height: 21px;
    }

    .webkit_center {
        font-size: 20px;
    }

    .scrollable-div{
        height: 18.5rem;
    }
}
@media screen and (min-height: 799px) {
    .scrollable-div{
        height: 17rem;
    }
}
@media screen and (min-height: 999px) {
    .scrollable-div{
        height: 22rem;
    }

    .radio_label{
        font-size: 24px;
        line-height: 2.5rem;
    }
    .radio_label_checked{
        font-size: 24px;
        line-height: 2.5rem;
    }
    .webkit_center{
        font-size: 24px;
        padding-bottom: 0;
    }
}
