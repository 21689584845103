

*{
    margin: 0%;
    padding: 0%;
}
@font-face {
    font-family: "Nexa Bold";
    src: local("Nexa Bold"),
     url("./fonts/NexaBold/Nexa_Bold.otf") format("opentype");
    font-weight: bold;
}
@font-face {
    font-family: "Nexa Book";
    src: local("Nexa Book"),
     url("./fonts/NexaBook/Nexa_Book.otf") format("opentype");
    font-weight: normal;
}
@font-face {
    font-family: "Nexa Light";
    src: local("Nexa Light"),
        url("./fonts/NexaLight/Nexa_Light.otf") format("opentype");
    font-weight: normal;
}
@font-face {
    font-family: "Verdana";
    src: local("Verdana"),
    url("./fonts/Veradana/VERDANAI.TTF") format("opentype");
    font-weight: normal;
}
