.txt_maturity{
    color: #2C2E37;
    text-align: center;
    font-family: 'Nexa Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 2.5rem;
    line-height: 40px;
}

.txt_sub {
    font-family: 'Nexa Book';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    color: #2C2E37;
    text-align: center;
    line-height: 42px;
}

.txt_sub_v2 {
    font-family: 'Nexa Book';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    color: #2C2E37;
    text-align: center;
    line-height: 29px;
}

.text_padding {
    padding-top: 5%;
}

@media screen and (min-width: 768px) {
    .txt_maturity {
        font-size: 2.5rem;
        line-height: 47px;
    }
}
@media screen and (min-width: 1024px) {
    .txt_maturity {
        font-size: 3rem;
        line-height: 60px;
    }
}
@media screen and (min-width: 1280px) {
    .txt_maturity {
        font-size: 54px;
        line-height: 78px;
    }
}
@media screen and (min-width: 1440px) {
    .txt_maturity {
        font-size: 64px;
        line-height: 77px;
    }
}