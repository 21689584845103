.progress-bar {
    height: 20px;
}

.progress-kind {
    margin: 0;
    font-size: 8px;
}

.progress {
    height: 100% !important;
    border-radius: 5px;
    transition: width 0.3s ease;
}

.stage {
    display: inline-block;
    width: calc(100% / 6);
    text-align: center;
    color: #555;
    font-size: 14px;
    cursor: pointer;
}

.active {
    color: #007bff;
}

.font_style {
    font-family: 'Verdana';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
}

.last-progress {
    margin-right: 10px;
    overflow: visible !important;
}

.last-progress::after {
    /*content: "";*/
    /*position: absolute;*/
    /*width: 30px;*/
    /*height: 32px;*/
    /*border-radius: 5px;*/
    /*right: -8px;*/
    /*transform: skew(25deg);*/
    /*z-index: -1000;*/
}


@media screen and (min-width: 768px) {
    .progress-bar {
        height: 24px;
    }

    .progress-kind {
        font-size: 9px;
    }
}

@media screen and (min-width: 1024px) {
    .progress-bar {
        height: 28px;
    }

    .progress-kind {
        font-size: 10px;
    }
}

@media screen and (min-width: 1280px) {
    .progress-bar {
        height: 30px;
    }

    .progress-kind {
        font-size: 13px;
    }
}


@media screen and (min-width: 1440px) {
    .progress-bar {
        height: 32px;
    }

    .progress-kind {
        font-size: 16px;
    }
}
