
.radio_container {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    line-height: 2.25rem;
    font-family: "Nexa Book";
    font-size: 1.5rem;
}

.radio_group {
    display: inline-block;
    vertical-align: middle;
}


.divider {
    display: inline-block;
    width: 60%;
    height: 2px;
    margin: 10px auto;
    background-color: #a59b9b;
}


.radio_divider {
    display: flex;
    padding-bottom: 10px;
}

.label_text_number {
    width: 10px;
    text-align: center;
    /*padding-left: 5px;*/
}

.group_wrapper {
    height: 230px;
}

.blocked_labels {
    width: 13.75rem;
}

.radio_label {
    font-size: 24px;
    margin-left: 30px;
    font-family: "Nexa Book";
    color: #2C2E37;
}

.radio_label_checked {
    font-size: 24px;
    margin-left: 30px;
    font-family: "Nexa Bold";
    color: #2C2E37;
}

.label_content {
    margin-top: 200px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    background-color: #555555;
}

/*.input_wrapper {*/
/*    width: 200px;*/
/*}*/

.horizontal_input[type="radio"] {
    appearance: none;
    box-sizing: border-box;
    background-color: #fff;
    font: inherit;
    color: currentColor;
    width: 24px;
    height: 24px;
    border: 2px solid var(--form-control-color);
    border-radius: 15px;
    flex: none;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
}
.horizontal_input[type="radio"]::before {
    content: "";
    width: 20px;
    height: 20px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);

}

.horizontal_input[type="radio"]:checked::before {
    transform: scale(0.7);
}

.horizontal_input[type="radio"]:focus {
    outline: max(0, 0.15em) solid var(--form-control-color);
    outline-offset: max(2px, 0.15em);
}

.radio_container .horizontal_input[type="radio"]:checked + lable {
    font-weight: bold;
    background-color: black;
}

.text-container{
    display: flex;
    padding-bottom: 15px;
}
.input-div{
    width: 40%;
    text-align: -webkit-center;
}
@media screen and (max-width: 1281px) {
    .radio_label{
        font-size: 20px;
    }
    .radio_label_checked{
        font-size: 20px;
    }

}