.text_register_form {
    font-style: normal;
    font-weight: 700;
    font-size: 2.25rem;
    line-height: 3rem;
    color: #383839;
    font-family: "Nexa Bold";
    width: 20.25rem;
}

.nxt_btn {
    cursor: pointer;
    font-weight: 700;
    font-size: 1.5rem;
    font-style: normal;
    color: #1C5371;
    font-font: "Nexa Bold";
    line-height: 1.813rem;
    box-sizing: border-box;
    padding: 1rem 2rem;
    height: 3.8rem;
    border: 1px solid #1C5371;
    border-radius: 0.625rem;
    background: transparent;
}

.flex_center {
    display: flex;
    justify-content: center;
    align-items: center;
    /*flex-direction: column;*/
}